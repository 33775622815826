<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useMutation, useQuery } from '@urql/vue';
import { useToast, useModal, CallMeBack } from '@mogelijk-technologies/ui-library';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useAuthStore } from '@stores/useAuthStore';
import { CallBack } from '@components/Contact';
import { CREATE_CALLBACK } from '@graphql/mutations/callback';
import { AVAILABLE_CALLBACK_OPTIONS_QUERY } from '@graphql/queries/callback';

import type { AvailableCallBackOption } from '@mogelijk-technologies/ui-library';
import type { AvailableCallBackOptionsResponse } from '@graphql/types/callback';

export interface Props {
  isActive?: boolean;
}

withDefaults(defineProps<Props>(), {
  isActive: false,
});

type CallMeBackKey = 'default' | 'current' | 'funds';

const { user } = toRefs(useAuthStore());
const { getUserPhoneNumbers } = useAuthStore();

const contactData = {
  current: {
    phoneNumber: '0346 - 250 171',
    emailAddress: 'info@mogelijk.nl',
    photo: '/rozalinda.jpg',
  },
  funds: {
    phoneNumber: '0346 - 264 664',
    emailAddress: 'fondsbeleggen@mogelijk.nl',
    photo: '/antoinette.jpeg',
  },
  default: {
    phoneNumber: '0346 - 250 171',
    emailAddress: 'info@mogelijk.nl',
    photo: '/brechtje.jpeg',
  },
};

const route = useRoute();
const callMeBackMeta = route.meta.callMeBack as CallMeBackKey;
const callBack = ref<CallBack | null>(null);
const availableOptions = ref<AvailableCallBackOption[] | null>(null);

const isCompleted = ref(false);

const { executeMutation: createCallBack, fetching: isFetching } = useMutation(CREATE_CALLBACK);

const getInitialCallBackValue = () => {
  const phoneNumbers = getUserPhoneNumbers();

  return {
    name: user.value ? user.value.name : '',
    phone: phoneNumbers && phoneNumbers.length > 0 ? phoneNumbers[0] : '',
    callback_date: '',
    callback_time: '08:30',
    via: route ? route.meta.callMeBack as string : '',
  };
};

const requestCall = async (callback: CallBack) => {
  callBack.value = callback;

  if (callBack.value.callback_time) {
    callBack.value.callback_time = callBack.value.callback_time.padStart(5, '0');
  }

  let toast;
  const { data } = await createCallBack({ input: callback });

  if (data) {
    toast = await useToast({
      title: 'Succes',
      message: 'Terugbelverzoek succesvol ingediend',
      theme: 'success',
    });
  } else {
    toast = await useToast({
      title: 'Er is iets fout gegaan',
      message: 'Probeer het later opnieuw',
      theme: 'error',
    });
  }

  isCompleted.value = true;
  toast.present();
};

const { executeQuery: fetchAvailableCallbackOptions } = useQuery<AvailableCallBackOptionsResponse>({
  query: AVAILABLE_CALLBACK_OPTIONS_QUERY,
});

const setModal = async () => {
  isCompleted.value = false;

  const { data, error } = await fetchAvailableCallbackOptions();
  const { availableCallBackOptions } = data.value || { availableCallBackOptions: [] };

  if (error.value) {
    const toast = await useToast({
      message: error.value?.message,
      theme: 'error',
    });

    toast.present();
  }

  availableOptions.value = availableCallBackOptions;

  useModal({
    title: 'Terugbelverzoek',
    component: CallMeBack,
    props: {
      isFetching,
      isCompleted,
      modelValue: getInitialCallBackValue(),
      availableOptions,
    },
    events: {
      createCallBack: (callback: CallBack) => requestCall(callback),
    },
  });
};

onMounted(() => {
  callBack.value = getInitialCallBackValue();
});
</script>

<template>
  <div class="hidden tablet:flex">
    <div class="flex w-44 items-end justify-end mb-3">
      <div
        data-test-id="contact-button"
        class="peer relative mt-6 flex size-12 cursor-pointer items-center justify-center whitespace-nowrap rounded-full
        bg-white px-4 font-semibold text-blue-900 drop-shadow transition-all duration-300 ease-in-out
        hover:mt-24 hover:w-full hover:drop-shadow-xl"
        :class="{'!mt-24 w-full !drop-shadow-xl': isActive}"
        @click="setModal"
      >
        <div class="overflow-hidden">
          <FontAwesomeIcon :icon="['fas', 'phone']" size="1x" class="mr-3" /> Bel me terug
        </div>
      </div>
      <div
        class="absolute -top-7 right-0 mr-6 size-5 opacity-0 transition-all duration-300 ease-in-out peer-hover:top-2 peer-hover:mr-0
        peer-hover:size-20 peer-hover:opacity-100"
        :class="{'top-2 !mr-0 !h-20 !w-20 opacity-100': isActive}"
      >
        <img
          :src="contactData[callMeBackMeta || 'default'].photo"
          alt="Helpdesk"
          class="size-full rounded-full object-cover drop-shadow"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.no-blur {
  image-rendering: -webkit-optimize-contrast;
}
</style>
